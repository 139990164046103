// import { GlobalConst } from '../appConfig/globalConst';
import { deleteCookie } from '../helper/genHelper';
import { updateToken } from './reducers/authReducer';
import store from './store';

export const checkAuthToken = async () => {

  // console.log("Cookie", document.cookie);
  // let tokenSession = document.cookie.split('; ').find(row => row.startsWith('session_token='));
  // let refresh_token = document.cookie.split('; ').find(row => row.startsWith('refresh_token='));

  // if (tokenSession) {
  //   tokenSession = tokenSession.split('=')[1];
  //   refresh_token = refresh_token.split('=')[1];

  //   await store.dispatch(updateToken(tokenSession));
  //   localStorage.setItem('__t', tokenSession);
  //   localStorage.setItem('__r', refresh_token);
  // }


  // console.log("Token Session", tokenSession);
  // console.log("refresh_token Session", refresh_token);



  const token = localStorage.getItem('__t');
  if (token) {
    await store.dispatch(updateToken(token));
  }

  // remove the cookie from session storage
  deleteCookie();

};