import { createSlice } from "@reduxjs/toolkit";

const productsReducer = createSlice({
  name: 'productsReducer',
  initialState: {
      subscribedProducts: null
  },
  reducers: {
      setSubscribedProducts: (state, action) => {
          state.subscribedProducts = action.payload;
      }
  }
})

export const { setSubscribedProducts } = productsReducer.actions;

export default productsReducer.reducer;
