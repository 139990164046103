import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faCircleQuestion, faRotate, faRotateRight, faThumbsDown, faWandMagicSparkles, faXmark } from "@fortawesome/free-solid-svg-icons";
import { getPackageName } from "../../shared/helper/genHelper";
import {
  Result,
  Button,
  Tooltip,
  Drawer,
  Modal,
  Radio,
} from "antd";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useSelector } from "react-redux";
import ModuleView from "../scannerPage/moduleView";
import Forensic from "../scannerPage/forensic";
import LinkedGraph from "../scannerPage/linkedGraph";
import TidyTreeView from "../scannerPage/tidyTree";
import VulDataTable from "./vulDataTable";
import ComponentDataTable from "../scannerPage/componentDataTable";
import Loader from "../../shared/sharedComponents/loader";
import WelcomeLoader from "../welcomeScreen/loader";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import AutoFixVulnerabilityModal from "../scaDashboard/autoFixVulnerabilityModal";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import moment from "moment";
import LayerDataTable from "./layerDataTable";
import TrendGraph from "./trendGraph";
import TabLoader from "../../shared/sharedComponents/tabLoader";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import OWASP10TabView from "./OWASP10TabView";
import awsCi from "../../assets/img/scopy-tech-icons/aws.svg";
import jfrog from "../../assets/img/scopy-tech-icons/jfrog.svg";
import ibm from "../../assets/img/scopy-tech-icons/ibm.svg";
import azureRepos from "../../assets/img/azure-repos.svg";
import googleCloud from "../../assets/img/scopy-tech-icons/gcp.svg";
import { faDocker } from "@fortawesome/free-brands-svg-icons";
import HistoryTabView from "./history";

export default function ScanDetails() {
  const { id } = useParams();
  const history = useHistory();
  const [reportDetails, setReportDetails] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFileRecord, setSelectedFileRecord] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [openFixVulnerability, setOpenFixVulnerability] = useState(false);
  const [isEnableTraceibility, setIsEnableTraceibility] = useState(false);
  const [userPrefrences, setUserPrefrences] = useState(null);
  const [trendValue, setTrendValue] = useState("7");
  const [trendData, setTrendData] = useState({});
  const [loadingTrendData, setLoadingTrendData] = useState(false);
  const [owaspTop10CveCountMetadataList, setOwaspTop10CveCountMetadataList] =
    useState(null);

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios
        .get(
          `${GlobalConst.API_URL}/auth/foss/report/${id}?st=${isEnableTraceibility}`
        )
        .then((op) => {
          setLoading(false);
          if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
            setReportDetails(op.data);
            setSearchResults(op.data.scannerResponseEntity);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("Exception: ", e);
        });
    }
  }, [id]);

  const reduxState = useSelector((state) => state);

  const [suggestedRemediation, setSuggestedRemediation] = useState({});
  const [loadedResults, setLoadedResults] = useState(false);
  const [selectedFile, setSelectedFile] = useState("all");
  const [fileOptions, setFileOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [clickedCWE, setClickedCWE] = useState("");
  const [whatNextModal, setWhatNextModal] = useState(false);

  const [openSecurityDrawer, setOpenSecurityDrawer] = useState(false);
  const [key, setKey] = useState("vulView");

  // useEffect(() => {
  //   async function isSessionActive() {
  //     let ls = await localStorage.getItem("__t");
  //     if (_.isEmpty(ls)) {
  //       history.push("/");
  //       window.location.href = GlobalConst.BASE_URL + "/login";
  //     }
  //   }
  //   isSessionActive();
  // }, []);

  useEffect(() => {
    if (!_.isEmpty(searchResults)) {
      populateValues(searchResults);
    }
  }, [searchResults]);

  useEffect(() => {
    let userId =
      !_.isEmpty(reduxState.authReducer) &&
        !_.isEmpty(reduxState.authReducer.userDetails) &&
        !_.isEmpty(reduxState.authReducer.userDetails.email)
        ? reduxState.authReducer.userDetails.email
        : "";

    if (!_.isEmpty(userId)) {
      getPrefrences(userId);
    }
  }, [reduxState.authReducer]);

  useEffect(() => {
    let userId =
      !_.isEmpty(reduxState.authReducer) &&
        !_.isEmpty(reduxState.authReducer.userDetails) &&
        !_.isEmpty(reduxState.authReducer.userDetails.email)
        ? reduxState.authReducer.userDetails.email
        : "";

    if (
      !_.isEmpty(userId) &&
      !_.isEmpty(reportDetails) &&
      !_.isEmpty(reportDetails.location)
    ) {
      getTrendData(trendValue, reportDetails.location);
    }
  }, [trendValue, reportDetails, reduxState.authReducer]);

  const getCountOnFilter = (filter, rowData) => {
    let count = 0;
    if (!_.isEmpty(rowData)) {
      _.forIn(rowData, (value, key) => {
        if (!_.isEmpty(value) && !_.isEmpty(value.cves)) {
          let ct = getCount(value);
          if (!_.isEmpty(filter)) {
            filter.forEach((el) => {
              count = count + ct[el];
            });
          } else {
            count =
              count +
              (parseInt(ct.CRITICAL) +
                parseInt(ct.HIGH) +
                parseInt(ct.MEDIUM) +
                parseInt(ct.LOW));
          }
        }
      });
    }
    return count;
  };

  const getCount = (rowData) => {
    let count = { CRITICAL: 0, HIGH: 0, MEDIUM: 0, LOW: 0 };
    if (!_.isEmpty(rowData) && !_.isEmpty(rowData.cves)) {
      rowData.cves.forEach((elem) => {
        if (!_.isEmpty(elem)) {
          if (!_.isEmpty(elem.cvssV3BaseSeverity)) {
            count[elem.cvssV3BaseSeverity] = count[elem.cvssV3BaseSeverity] + 1;
          } else {
            if (!_.isEmpty(elem.cvssV2BaseSeverity)) {
              count[elem.cvssV2BaseSeverity] =
                count[elem.cvssV2BaseSeverity] + 1;
            }
          }
        }
      });
    }
    return count;
  };

  const handleButtonClick = (e) => {
    history.push(`/fix-vulnerabilities/${id}`);
  };

  const getFileLocation = (file) => {
    let fileSplit = file.split("/");
    return `${fileSplit[fileSplit.length - 2]}/${fileSplit.pop()}`;
  };

  useEffect(() => {
    if (searchResults) {
      let options = [];
      if (!_.isEmpty(searchResults) && !_.isEmpty(searchResults.scanResult)) {
        searchResults.scanResult.forEach((elem, index) => {
          if (getCountOnFilter(selectedFilter, elem.scanResult) > 0) {
            options.push({
              key: `${index}-row`,
              value: index,
              label: getFileLocation(elem.scanFileLocation),
              count: getCountOnFilter(selectedFilter, elem.scanResult),
              critical:
                !_.isEmpty(elem.cveCountDetails) &&
                  _.has(elem.cveCountDetails, "CRITICAL")
                  ? elem.cveCountDetails.CRITICAL
                  : 0,
              high:
                !_.isEmpty(elem.cveCountDetails) &&
                  _.has(elem.cveCountDetails, "HIGH")
                  ? elem.cveCountDetails.HIGH
                  : 0,
              medium:
                !_.isEmpty(elem.cveCountDetails) &&
                  _.has(elem.cveCountDetails, "MEDIUM")
                  ? elem.cveCountDetails.MEDIUM
                  : 0,
              low:
                !_.isEmpty(elem.cveCountDetails) &&
                  _.has(elem.cveCountDetails, "LOW")
                  ? elem.cveCountDetails.LOW
                  : 0,
              total: !_.isEmpty(elem.totalCve) ? elem.totalCve : 0,
              rowData: searchResults.scanResult,
              scanFileLocation: elem.scanFileLocation,
            });
          }
        });
      }
      setFileOptions(options);
    }
  }, [selectedFile, selectedFilter]);

  const populateValues = (data) => {
    let options = [];
    if (!_.isEmpty(data) && !_.isEmpty(data.scanResult)) {
      data.scanResult.forEach((elem, index) => {
        if (elem.totalCve > 0) {
          options.push({
            key: `${index}-row`,
            value: index,
            label: getFileLocation(elem.scanFileLocation),
            count: elem.totalCve,
            critical:
              !_.isEmpty(elem.cveCountDetails) &&
                _.has(elem.cveCountDetails, "CRITICAL")
                ? elem.cveCountDetails.CRITICAL
                : 0,
            high:
              !_.isEmpty(elem.cveCountDetails) &&
                _.has(elem.cveCountDetails, "HIGH")
                ? elem.cveCountDetails.HIGH
                : 0,
            medium:
              !_.isEmpty(elem.cveCountDetails) &&
                _.has(elem.cveCountDetails, "MEDIUM")
                ? elem.cveCountDetails.MEDIUM
                : 0,
            low:
              !_.isEmpty(elem.cveCountDetails) &&
                _.has(elem.cveCountDetails, "LOW")
                ? elem.cveCountDetails.LOW
                : 0,
            rowData: data.scanResult,
            scanFileLocation: elem.scanFileLocation,
          });
        }
      });
    }
    setSelectedFile(0);
    setFileOptions(options);
    setSelectedFileRecord(data.scanResult);
    setOwaspTop10CveCountMetadataList(data.owaspTop10CveCountMetadataList);
  };

  const handleFileNameChange = (e) => {
    setSelectedFile(e);
  };

  const getCWEDetails = () => {
    let row = {};
    if (
      !_.isEmpty(searchResults) &&
      !_.isEmpty(searchResults.cweCveCountMetadataList)
    ) {
      row = searchResults.cweCveCountMetadataList[clickedCWE];
    }
    return row;
  };

  const handleSeverityFilterChange = (e, val) => {
    if (e.target.checked) {
      setSelectedFilter([...selectedFilter, val]);
    } else {
      setSelectedFilter(_.filter(selectedFilter, (elm) => elm !== val));
    }
  };

  const getFormatedText = (text) => {
    let splitedText = [];
    if (!_.isEmpty(text)) {
      splitedText = text.split("#$");
    }
    if (!_.isEmpty(splitedText)) {
      return (
        <span>
          {splitedText[0]}
          <span className="me-1" style={{}}>
            {splitedText[1]}
          </span>
          {splitedText[2]}
        </span>
      );
    } else {
      return "";
    }
  };

  const getComponentCount = () => {
    let count = 0;
    if (!_.isEmpty(searchResults)) {
      searchResults.scanResult.forEach((el, index) => {
        if (!_.isEmpty(el) && !_.isEmpty(el.scanResult)) {
          _.forIn(el.scanResult, (val) => {
            if (!_.isEmpty(val) && !_.isEmpty(val.cves)) {
              count = count + 1;
            }
          });
        }
      });
    }
    return count;
  };

  const getModuleCount = () => {
    let count = 0;
    if (!_.isEmpty(searchResults)) {
      searchResults.scanResult.forEach((el, index) => {
        count = count + 1;
      });
    }
    return count;
  };

  const getVulModuleCount = () => {
    let count = 0,
      isEmpty = true;
    if (!_.isEmpty(searchResults)) {
      searchResults.scanResult.forEach((el, index) => {
        if (!_.isEmpty(el) && !_.isEmpty(el.scanResult)) {
          isEmpty = true;
          _.forIn(el.scanResult, (val) => {
            if (!_.isEmpty(val) && !_.isEmpty(val.cves)) {
              isEmpty = false;
            }
          });
          if (!isEmpty) {
            count = count + 1;
          }
        }
      });
    }
    return count;
  };

  const showButton = (sr) => {
    if (!_.isEmpty(sr) && !_.isEmpty(sr.scanResult)) {
      const containsMaven = _.some(sr.scanResult, (obj) =>
        _.includes(obj.scanType, "maven")
      );
      // Output the result
      if (containsMaven) {
        return true;
      } else {
        return false;
      }
    }
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (e) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const getReportName = (location) => {
    if (isValidHttpUrl(location)) {
      var pathname = new URL(location).pathname;
      pathname = pathname.substring(1);
      if (searchResults.assetSubtype === "azure-scm") {
        location = `${location}?version=GB${searchResults.branch}`;
      }

      return (
        <Tooltip title={location}>
          <a href={location} target="_blank" rel="noreferrer noopener">
            {!_.isEmpty(pathname) ? decodeURIComponent(pathname) : location}
          </a>
        </Tooltip>
      );
    } else {
      return <Tooltip title={location}>{location}</Tooltip>;
    }
  };

  const getPrefrences = (userId) => {
    const postObj = {
      userId: userId,
    };
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/user/get-preference`, postObj)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
          setUserPrefrences(op.data);
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
      });
  };

  const getTracebilityCount = () => {
    let countData = {
      tracebleCount: 0,
      traceWithVulCount: 0,
    };
    if (!_.isEmpty(searchResults)) {
      searchResults.scanResult.forEach((el, index) => {
        if (!_.isEmpty(el) && !_.isEmpty(el.scanResult)) {
          _.forIn(el.scanResult, (val) => {
            if (!_.isEmpty(val) && !_.isEmpty(val.traceabilityDetails)) {
              countData.tracebleCount += 1;
              if (!_.isEmpty(val.cves)) countData.traceWithVulCount += 1;
            }
          });
        }
      });
    }
    return countData;
  };

  const handleTrendFilterChange = (e) => {
    setTrendValue(e.target.value);
  };

  const getTrendData = (trendValue, assetUrl) => {
    let postData = {
      userId: reduxState.authReducer.userDetails.email,
      assetUrl,
      days: trendValue ? trendValue : "7",
    };
    setLoadingTrendData(true);
    setTrendData({});
    axios
      .post(`${GlobalConst.API_URL}/auth/foss/user/asset-scan-trend`, postData)
      .then((op) => {
        if (
          !_.isEmpty(op) &&
          !_.isEmpty(op.data) &&
          !_.isEmpty(op.data.content)
        ) {
          const trendData = [];
          const sortedDates = Object.keys(op.data.content).sort((a, b) =>
            moment(a).diff(moment(b))
          );

          sortedDates.forEach((scanDate) => {
            const {
              CRITICAL: totalCriticalCount,
              HIGH: totalHighCount,
              LOW: totalLowCount,
              MEDIUM: totalMediumCount,
              totalCve: totalVulnerabilityCount,
            } = op.data.content[scanDate];
            trendData.push({
              scanDate,
              totalVulnerabilityCount,
              totalCriticalCount,
              totalHighCount,
              totalMediumCount,
              totalLowCount,
            });
          });

          setTrendData(trendData);
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
      })
      .finally((e) => {
        setLoadingTrendData(false);
      });
  };

  const getRegistryAssetIcon = (registryType) => {
    const card = {
      alt: "title",
    };
    switch (registryType) {
      case "ibmcr":
        return (
          <img
            src={ibm}
            alt={"IBM"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "jfrog":
        return (
          <img
            src={jfrog}
            alt={"Jfrog"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "awsecr":
        return (
          <img
            src={awsCi}
            alt={"AWS"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "azurecr":
        return (
          <img
            src={azureRepos}
            alt={"Azure"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      case "dockerhub":
        return (
          <FontAwesomeIcon
            title="docker"
            icon={faDocker}
            style={{ width: 22 }}
          />
        );
      case "gcr":
        return (
          <img
            src={googleCloud}
            alt={"Google"}
            className="img-fluid"
            style={{ width: 22 }}
          />
        );
      default:
        return (
          <Tooltip title="Unable to identify the artifact">
            <span>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </span>
          </Tooltip>
        );
    }
  };

  const handleRescanClick = () => {
    let isDemoUser = localStorage.getItem('isDemoUser');
    if (isDemoUser && isDemoUser === 'true') {
      alert("Scan has been disabled for this account");
    }
    else {
      if (
        !_.isEmpty(reportDetails) &&
        !_.isEmpty(reportDetails.scannerResponseEntity)
      ) {
        const requestBody = {
          urlType: reportDetails.scannerResponseEntity.assetSubtype,
          location: reportDetails.scannerResponseEntity.assetUrl,
        };
        setScanning(true);
        axios
          .post(`${GlobalConst.API_URL}/auth/foss/scan?enableSbom=true`, requestBody)
          .then(async (op) => {
            setScanning(false);
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
              window.location.href = `/container-security/${op.data.reportId}`;
            }
          })
          .catch((e) => {
            console.log("Exception: ", e);
            setScanning(false);
            if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data) && !_.isEmpty(e.response.data.errorMessage)) {
              message.error({
                content: (
                  <div className="d-flex align-items-center">
                    {e.response.data.errorMessage}
                  </div>
                ),
                icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                onClose: () => message.destroy(),
              });
            }
            else {
              message.error({
                content: (
                  <div className="d-flex align-items-center">
                    Undefined Error occured.
                  </div>
                ),
                icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                onClose: () => message.destroy(),
              });
            }
          });
      }
    }
  };

  const handleDashboardReload = () => {
    window.location.reload();
  };


  const isShowModule = false;
  const isShowBubble = false;

  return (
    <section className="mx-3">
      <div className="text-white mb-2 d-flex align-items-center justify-content-start">
        <h2 className="mb-0 text-white" style={{ minWidth: 200 }}>
          Scan Report
        </h2>

        {!_.isEmpty(searchResults) && searchResults.assetType !== "file" && (
          <Button
            onClick={handleRescanClick}
            className="custom-button btn-sm ms-3"
            loading={scanning}
          >
            <FontAwesomeIcon icon={faRotate} className="me-2" />
            Re-Scan
          </Button>
        )}
        <Tooltip title="Refresh">
          <button
            className="btn btn-primary py-1 ms-2"
            onClick={handleDashboardReload}
          >
            <FontAwesomeIcon icon={faRotateRight} />
          </button>
        </Tooltip>
        {!_.isEmpty(searchResults) && showButton(searchResults) && (
          <Button
            type="primary"
            onClick={handleButtonClick}
            className="ms-3 fix-btn"
            disabled={scanning}
          >
            <FontAwesomeIcon icon={faWandMagicSparkles} className="me-2" />
            Apply Auto Fix
          </Button>
        )}
        {scanning && (
          <span className="d-flex align-items-center justify-content-start ms-3">
            <WelcomeLoader />{" "}
            <small className="ms-1" style={{ fontSize: 14, fontWeight: 200 }}>
              Scanning...
            </small>
          </span>
        )}
      </div>
      <div className="">
        <div>
          {!_.isEmpty(searchResults) && (
            <>
              <span className="d-flex align-items-center justify-content-start">
                <span className="text-truncate me-2">
                  {!_.isEmpty(searchResults) &&
                    !_.isEmpty(searchResults.scanResult) && (
                      <span className="pe-2">
                        {getRegistryAssetIcon(
                          searchResults.scanResult[0].registryType
                        )}
                      </span>
                    )}
                  {!_.isEmpty(reportDetails) &&
                    !_.isEmpty(reportDetails.location) &&
                    getReportName(reportDetails.location)}
                </span>
                {(!_.isEmpty(searchResults) && !_.isEmpty(searchResults.imageVersion)) && <small className="d-block me-2">&lt;{searchResults.imageVersion}&gt;</small>}
                <span>
                  {!_.isEmpty(searchResults) &&
                    !_.isEmpty(searchResults.scanDate) && (
                      <small className="d-block text-secondary" style={{}}>
                        <i>
                          (<strong>Last Scan:</strong>{" "}
                          {moment.utc(searchResults.scanDate).local().fromNow()}
                          )
                        </i>
                      </small>
                    )}
                </span>
              </span>
            </>
          )}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!_.isEmpty(searchResults) && (
            <div className="mb-4">
              <div className="row">
                <div
                  className="col-sm-6 pe-5"
                  style={{ borderRight: "1px solid #2b313e" }}
                >
                  <div className="d-flex align-items-center justify-content-start h-100">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="px-2 d-flex align-items-center justify-content-start">
                        <div className="total-header">
                          <div className="d-flex flex-column">
                            <p
                              className="head-tag-subtext my-0"
                              style={{
                                color: "#9a9a9a",
                                fontSize: "90%",
                                fontWeight: 500,
                              }}
                            >
                              Total vulnerabilities
                            </p>
                            <h1 className="head-tag text-white">
                              {!_.isEmpty(searchResults)
                                ? searchResults.totalCve
                                : "-"}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="px-2">
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                          <div className="py-2 pe-2 text-center">
                            <svg
                              width="120px"
                              height="120px"
                              viewBox="0 0 120 120"
                            >
                              <defs>
                                <linearGradient
                                  id="e"
                                  x1="40"
                                  y1="210"
                                  x2="460"
                                  y2="290"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="rgb(178,34,34)" offset="0" />
                                  <stop stopColor="rgb(178,34,34)" offset="1" />
                                </linearGradient>
                              </defs>
                              <g transform="translate(60 60)">
                                {[...new Array(45)].map((ind, index) => (
                                  <use
                                    key={`linear-critical${index}`}
                                    xlinkHref="#lines"
                                    transform={`rotate( ${index * 6})`}
                                  />
                                ))}
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="59"
                                  stroke="rgb(178,34,34)"
                                  strokeWidth="2"
                                  strokeDasharray="3"
                                  fill="none"
                                />
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="53"
                                  stroke="rgba(178,34,34,0.22)"
                                  strokeWidth="0"
                                  fill="rgba(218,74,71,1)"
                                />
                                <g x="0" y="0">
                                  <text
                                    x="0"
                                    y="-10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".3em"
                                    style={{ fontSize: 24, fontWeight: 600 }}
                                  >
                                    {" "}
                                    {!_.isEmpty(searchResults) &&
                                      searchResults.cveCountDetails &&
                                      searchResults.cveCountDetails.CRITICAL
                                      ? searchResults.cveCountDetails.CRITICAL
                                      : "0"}{" "}
                                  </text>
                                  <text
                                    x="0"
                                    y="10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".6em"
                                    style={{ fontSize: 16 }}
                                  >
                                    Critical
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="py-2 pe-2 text-center">
                            <svg
                              width="120px"
                              height="120px"
                              viewBox="0 0 120 120"
                            >
                              <defs>
                                <linearGradient
                                  id="e1"
                                  x1="0"
                                  y1="0"
                                  x2="140"
                                  y2="120"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    stopColor="rgb(242, 109, 36)"
                                    offset="0"
                                  />
                                  <stop stopColor="steelblue" offset="1" />
                                </linearGradient>
                              </defs>
                              <g transform="translate(60 60)">
                                {[...new Array(45)].map((ind, index) => (
                                  <use
                                    key={`linear-high${index}`}
                                    xlinkHref="#lines2"
                                    transform={`rotate( ${index * 6})`}
                                  />
                                ))}
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="59"
                                  stroke="rgb(242, 109, 36)"
                                  strokeWidth="2"
                                  strokeDasharray="3"
                                  fill="none"
                                />
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="53"
                                  stroke="rgba(242, 109, 36,0.22)"
                                  strokeWidth="0"
                                  fill="rgba(214, 112, 67,1)"
                                />
                                <g x="0" y="0">
                                  <text
                                    x="0"
                                    y="-10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".3em"
                                    style={{ fontSize: 24, fontWeight: 600 }}
                                  >
                                    {" "}
                                    {!_.isEmpty(searchResults) &&
                                      searchResults.cveCountDetails &&
                                      searchResults.cveCountDetails.HIGH
                                      ? searchResults.cveCountDetails.HIGH
                                      : "0"}{" "}
                                  </text>
                                  <text
                                    x="0"
                                    y="10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".6em"
                                    style={{ fontSize: 16 }}
                                  >
                                    High
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="py-2 pe-2 text-center">
                            <svg
                              width="120px"
                              height="120px"
                              viewBox="0 0 120 120"
                            >
                              <defs>
                                <linearGradient
                                  id="e3"
                                  x1="40"
                                  y1="210"
                                  x2="460"
                                  y2="290"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    stopColor="rgb(248, 168, 20)"
                                    offset="0"
                                  />
                                  <stop stopColor="red" offset="1" />
                                </linearGradient>
                              </defs>
                              <g transform="translate(60 60)">
                                {[...new Array(45)].map((ind, index) => (
                                  <use
                                    key={`linear-medium${index}`}
                                    xlinkHref="#lines3"
                                    transform={`rotate( ${index * 6})`}
                                  />
                                ))}
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="59"
                                  stroke="rgb(248, 168, 20)"
                                  strokeWidth="2"
                                  strokeDasharray="3"
                                  fill="none"
                                />
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="53"
                                  stroke="rgba(248, 168, 20,0.22)"
                                  strokeWidth="0"
                                  fill="rgba(241, 162, 54,1)"
                                />
                                <g x="0" y="0">
                                  <text
                                    x="0"
                                    y="-10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".3em"
                                    style={{ fontSize: 24, fontWeight: 600 }}
                                  >
                                    {" "}
                                    {!_.isEmpty(searchResults) &&
                                      searchResults.cveCountDetails &&
                                      searchResults.cveCountDetails.MEDIUM
                                      ? searchResults.cveCountDetails.MEDIUM
                                      : "0"}{" "}
                                  </text>
                                  <text
                                    x="0"
                                    y="10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".6em"
                                    style={{ fontSize: 16 }}
                                  >
                                    Medium
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="py-2 pe-2 text-center">
                            <svg
                              width="120px"
                              height="120px"
                              viewBox="0 0 120 120"
                            >
                              <defs>
                                <linearGradient
                                  id="e4"
                                  x1="40"
                                  y1="210"
                                  x2="460"
                                  y2="290"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    stopColor="rgb(253, 235, 72)"
                                    offset="0"
                                  />
                                  <stop stopColor="red" offset="1" />
                                </linearGradient>
                              </defs>
                              <g transform="translate(60 60)">
                                {[...new Array(45)].map((ind, index) => (
                                  <use
                                    key={`linear-low${index}`}
                                    xlinkHref="#lines4"
                                    transform={`rotate( ${index * 6})`}
                                  />
                                ))}
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="59"
                                  stroke="rgb(253, 235, 72)"
                                  strokeWidth="2"
                                  strokeDasharray="3"
                                  fill="none"
                                />
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="53"
                                  stroke="rgba(253, 235, 72,0.22)"
                                  strokeWidth="0"
                                  fill="rgba(224, 230, 183,1)"
                                />
                                <g x="0" y="0">
                                  <text
                                    x="0"
                                    y="-10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".3em"
                                    style={{ fontSize: 24, fontWeight: 600 }}
                                  >
                                    {" "}
                                    {!_.isEmpty(searchResults) &&
                                      searchResults.cveCountDetails &&
                                      searchResults.cveCountDetails.LOW
                                      ? searchResults.cveCountDetails.LOW
                                      : "0"}{" "}
                                  </text>
                                  <text
                                    x="0"
                                    y="10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".6em"
                                    style={{ fontSize: 16 }}
                                  >
                                    Low
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="py-2 pe-2 text-center">
                            <svg
                              width="120px"
                              height="120px"
                              viewBox="0 0 120 120"
                            >
                              <defs>
                                <linearGradient
                                  id="e4"
                                  x1="40"
                                  y1="210"
                                  x2="460"
                                  y2="290"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    stopColor="rgb(253, 235, 72)"
                                    offset="0"
                                  />
                                  <stop stopColor="red" offset="1" />
                                </linearGradient>
                              </defs>
                              <g transform="translate(60 60)">
                                {[...new Array(45)].map((ind, index) => (
                                  <use
                                    key={`linear-low${index}`}
                                    xlinkHref="#lines4"
                                    transform={`rotate( ${index * 6})`}
                                  />
                                ))}
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="59"
                                  stroke="rgb(140, 140, 140)"
                                  strokeWidth="2"
                                  strokeDasharray="3"
                                  fill="none"
                                />
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="53"
                                  stroke="rgba(140, 140, 140, 0.22)"
                                  strokeWidth="0"
                                  fill="rgba(145, 145, 145, 1)"
                                />
                                <g x="0" y="0">
                                  <text
                                    x="0"
                                    y="-10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".3em"
                                    style={{ fontSize: 24, fontWeight: 600 }}
                                  >
                                    {" "}
                                    {!_.isEmpty(searchResults) &&
                                      searchResults.cveCountDetails &&
                                      searchResults.cveCountDetails.NA
                                      ? searchResults.cveCountDetails.NA
                                      : "0"}{" "}
                                  </text>
                                  <text
                                    x="0"
                                    y="10"
                                    textAnchor="middle"
                                    stroke="#212121"
                                    fill="#212121"
                                    strokeWidth="0"
                                    dy=".6em"
                                    style={{ fontSize: 16 }}
                                  >
                                    N/A
                                  </text>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="p-2">
                    <div className="card-body">
                      <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                        <h5 className="fw-medium">Vulnerability Trend</h5>
                        <div>
                          <Radio.Group
                            value={trendValue}
                            size="small"
                            buttonStyle="solid"
                            onChange={(e) => handleTrendFilterChange(e)}
                          >
                            <Radio.Button value="7" style={{ fontSize: 12 }}>
                              1 Week
                            </Radio.Button>
                            <Radio.Button value="14" style={{ fontSize: 12 }}>
                              2 Weeks
                            </Radio.Button>
                            <Radio.Button value="30" style={{ fontSize: 12 }}>
                              1 Month
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                      </div>
                      {!_.isEmpty(reportDetails) &&
                        !_.isEmpty(reportDetails.location) && (
                          <div style={{ height: 300 }}>
                            {_.isEmpty(trendData) ? (
                              loadingTrendData === true ? (
                                <div className="d-flex align-items-center justify-content-center h-100 position-relative">
                                  <TabLoader />
                                </div>
                              ) : (
                                <NoDataFound
                                  title={"No Trend Data Found"}
                                  desc={"We could not find any trend data"}
                                />
                              )
                            ) : (
                              <TrendGraph trendData={trendData} />
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              {searchResults &&
                !loading &&
                Object.keys(searchResults).length > 0 && (
                  <section className="pb-40 mt-2">
                    <div className="py-1">
                      <div
                        className=" text-white"
                        style={{ position: "relative" }}
                      >
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                          className="mt-4 mb-3 report-tabs text-white"
                          mountOnEnter={true}
                          unmountOnExit={true}
                        >
                          <Tab
                            eventKey="vulView"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>Vulnerability View</span>
                                <small
                                  className="d-block text-muted"
                                  style={{ fontSize: 14 }}
                                >
                                  <span
                                    className="text-danger"
                                    style={{ fontWeight: 700 }}
                                  >
                                    {!_.isEmpty(searchResults) &&
                                      searchResults.totalCve
                                      ? searchResults.totalCve
                                      : "0"}
                                  </span>{" "}
                                  vulnerabilities
                                </small>
                              </span>
                            }
                          >
                            <div
                              className="p-2"
                              style={{ marginTop: "0.2rem", borderRadius: 8 }}
                            >
                              {searchResults &&
                                Object.keys(searchResults).length > 0 ? (
                                <>
                                  {selectedFileRecord && (
                                    <div className="d-flex flex-column">
                                      <div className="row">
                                        <div className={`col-sm-12`}>
                                          <VulDataTable
                                            searchResults={searchResults}
                                            dataSet={selectedFileRecord}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {loadedResults && (
                                    <Result
                                      status="warning"
                                      title={
                                        <span style={{ fontSize: 20 }}>
                                          No data found
                                        </span>
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </Tab>
                          <Tab
                            eventKey="compView"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>Component View</span>
                                <small
                                  className="d-block text-muted"
                                  style={{ fontSize: 14 }}
                                >
                                  {" "}
                                  total{" "}
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      color: "#b3b3b3",
                                    }}
                                  >
                                    {!_.isEmpty(searchResults) &&
                                      searchResults.totalComponents
                                      ? searchResults.totalComponents
                                      : "0"}
                                  </span>{" "}
                                  (
                                  <span
                                    className="text-danger"
                                    style={{ fontWeight: 700 }}
                                  >
                                    {getComponentCount()}
                                  </span>{" "}
                                  with vul)
                                </small>
                              </span>
                            }
                          >
                            <div
                              className="p-2"
                              style={{ marginTop: "0.2rem", borderRadius: 8 }}
                            >
                              {searchResults &&
                                Object.keys(searchResults).length > 0 ? (
                                <>
                                  {selectedFileRecord && (
                                    <div className="d-flex flex-column">
                                      <div className="row">
                                        <div className={`col-sm-12`}>
                                          <ComponentDataTable
                                            searchResults={searchResults}
                                            dataSet={selectedFileRecord}
                                            filter={selectedFilter}
                                            handleSeverityFilterChange={
                                              handleSeverityFilterChange
                                            }
                                            selectedFile={selectedFile}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {loadedResults && (
                                    <Result
                                      status="warning"
                                      title={
                                        <span style={{ fontSize: 20 }}>
                                          No data found
                                        </span>
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </Tab>
                          {isShowModule && (
                            <Tab
                              eventKey="modView"
                              title={
                                <span style={{ lineHeight: 1 }}>
                                  <span>Module View</span>
                                  <small
                                    className="d-block text-muted"
                                    style={{ fontSize: 14 }}
                                  >
                                    {" "}
                                    total{" "}
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        color: "#b3b3b3",
                                      }}
                                    >
                                      {getModuleCount()}
                                    </span>{" "}
                                    (
                                    <span
                                      className="text-danger"
                                      style={{ fontWeight: 700 }}
                                    >
                                      {getVulModuleCount()}
                                    </span>{" "}
                                    with vul)
                                  </small>
                                </span>
                              }
                            >
                              <div
                                className="p-2"
                                style={{ marginTop: "0.2rem", borderRadius: 8 }}
                              >
                                {searchResults &&
                                  Object.keys(searchResults).length > 0 ? (
                                  <div>
                                    {selectedFileRecord && (
                                      <ModuleView
                                        searchResults={searchResults}
                                        data={fileOptions}
                                        onChange={handleFileNameChange}
                                        value={selectedFile}
                                        selectedFileRecord={selectedFileRecord}
                                        selectedFilter={selectedFilter}
                                        handleSeverityFilterChange={
                                          handleSeverityFilterChange
                                        }
                                        selectedFile={selectedFile}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    {loadedResults && (
                                      <Result
                                        status="warning"
                                        title={
                                          <span style={{ fontSize: 20 }}>
                                            No data found
                                          </span>
                                        }
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            </Tab>
                          )}
                          <Tab
                            eventKey="OWASP10View"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>
                                  OWASP Top 10
                                  <small
                                    className="d-block text-muted"
                                    style={{ fontSize: 14 }}
                                  >
                                    {" "}
                                    &nbsp;
                                  </small>
                                </span>
                              </span>
                            }
                          >
                            {searchResults &&
                              !_.isEmpty(owaspTop10CveCountMetadataList) ? (
                              <>
                                {selectedFileRecord && (
                                  <div className="d-flex flex-column">
                                    <div className="row">
                                      <div className={`col-sm-12`}>
                                        <OWASP10TabView
                                          searchResults={searchResults}
                                          dataSet={selectedFileRecord}
                                          reportDetails={
                                            owaspTop10CveCountMetadataList
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <NoDataFound title={"No OWASP data found"} />
                              </>
                            )}
                          </Tab>
                          <Tab
                            eventKey="zeroDayView"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>
                                  ZeroDay
                                  <small
                                    className="d-block text-muted"
                                    style={{ fontSize: 14 }}
                                  >
                                    {" "}
                                    &nbsp;
                                  </small>
                                </span>
                              </span>
                            }
                          >
                            {!_.isEmpty(searchResults) &&
                              !_.isEmpty(
                                searchResults.zeroDayCveCountMetadata
                              ) ? (
                              <>
                                {selectedFileRecord && (
                                  <div className="d-flex flex-column">
                                    <div className="row">
                                      <div className={`col-sm-12`}>
                                        {!_.isEmpty(
                                          searchResults.zeroDayCveCountMetadata
                                            .cveIdList
                                        ) ? (
                                          <VulDataTable
                                            cveIdList={
                                              searchResults
                                                .zeroDayCveCountMetadata
                                                .cveIdList
                                            }
                                            searchResults={searchResults}
                                            dataSet={selectedFileRecord}
                                          />
                                        ) : (
                                          <>
                                            <NoDataFound
                                              title={"No ZeroDay data found"}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <NoDataFound title={"No ZeroDay data found"} />
                              </>
                            )}
                          </Tab>
                          <Tab
                            eventKey="layerView"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>
                                  Layer View
                                  <small
                                    className="d-block text-muted"
                                    style={{ fontSize: 14 }}
                                  >
                                    {" "}
                                    &nbsp;
                                  </small>
                                </span>
                              </span>
                            }
                          >
                            <div
                              className="p-2"
                              style={{ marginTop: "0.2rem", borderRadius: 8 }}
                            >
                              {" "}
                              {searchResults &&
                                Object.keys(searchResults).length > 0 ? (
                                <>
                                  {selectedFileRecord && (
                                    <LayerDataTable
                                      dataSet={selectedFileRecord[0]}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  {loadedResults && (
                                    <Result
                                      status="warning"
                                      title={
                                        <span style={{ fontSize: 20 }}>
                                          No data found
                                        </span>
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </Tab>
                          <Tab
                            eventKey="linkedGraph"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>Linked Graph</span>
                                <small
                                  className="d-block text-muted"
                                  style={{ fontSize: 14 }}
                                >
                                  {" "}
                                  &nbsp;
                                </small>
                              </span>
                            }
                          >
                            <div style={{ marginTop: "0.2rem" }}>
                              <LinkedGraph
                                dataSet={selectedFileRecord}
                                searchResults={searchResults}
                              />
                            </div>
                          </Tab>
                          {isShowBubble && (
                            <Tab
                              eventKey="bubbleChart"
                              title={
                                <span style={{ lineHeight: 1 }}>
                                  <span>Bubble Chart</span>
                                  <small
                                    className="d-block text-muted"
                                    style={{ fontSize: 14 }}
                                  >
                                    {" "}
                                    &nbsp;
                                  </small>
                                </span>
                              }
                            >
                              <div style={{ marginTop: "0.2rem" }}>
                                <Forensic dataSet={selectedFileRecord} />
                              </div>
                            </Tab>
                          )}
                          <Tab
                            eventKey="tidytree"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>Tree View</span>
                                <small
                                  className="d-block text-muted"
                                  style={{ fontSize: 14 }}
                                >
                                  {" "}
                                  &nbsp;
                                </small>
                              </span>
                            }
                          >
                            <div style={{ marginTop: "0.2rem" }}>
                              <TidyTreeView dataSet={searchResults} />
                            </div>
                          </Tab>

                          <Tab
                            eventKey="history"
                            title={
                              <span style={{ lineHeight: 1 }}>
                                <span>History</span>
                                <small
                                  className="d-block text-muted"
                                  style={{ fontSize: 14 }}
                                >
                                  &nbsp;
                                </small>
                              </span>
                            }
                          >
                            <div style={{ marginTop: "0.2rem" }}>
                              <HistoryTabView
                                searchResults={searchResults}
                                id={id}
                                reportDetails={reportDetails}
                              />
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </section>
                )}
            </div>
          )}
        </>
      )}

      <Drawer
        title={
          <span className="text-white">Potential Security Threats Found</span>
        }
        placement="right"
        size={"large"}
        onClose={() => setOpenSecurityDrawer(false)}
        closeIcon={<FontAwesomeIcon className="text-white" icon={faXmark} />}
        open={openSecurityDrawer}
        className="custom-black-drawer"
      >
        <table className="table table-condensed custom-table white-color-header">
          <thead>
            <tr>
              <th>Vulnerability Description</th>
              <th>Where</th>
              <th style={{ width: 110 }}>CWE Id</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(searchResults) &&
              !_.isEmpty(searchResults.cweCveCountMetadataList) &&
              searchResults.cweCveCountMetadataList.map((row, index) => {
                return (
                  <tr key={`securityThreats${index}`}>
                    <td>
                      {!_.isEmpty(row.displayText)
                        ? getFormatedText(row.displayText)
                        : row.cveType}
                    </td>
                    <td>
                      {!_.isEmpty(row.vulnerablePackageList) &&
                        row.vulnerablePackageList.map((el, index) =>
                          index === row.vulnerablePackageList.length - 1
                            ? `${getPackageName(el)}`
                            : `${getPackageName(el)}, `
                        )}
                    </td>
                    <td>
                      <a
                        href={`#`}
                        onClick={(e) => {
                          e.preventDefault();
                          setClickedCWE(index);
                          setOpenDrawer(true);
                        }}
                        rel="noreferrer"
                      >
                        {row.cweId}
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Drawer>
      <Drawer
        title={<span className="text-white">Potential Security Threats</span>}
        placement="right"
        size={"large"}
        onClose={() => setOpenDrawer(false)}
        closeIcon={<FontAwesomeIcon className="text-white" icon={faXmark} />}
        open={openDrawer}
        className="custom-black-drawer"
        style={{ zIndex: 1001 }}
      >
        <p>This threat is introduced because of below CVE Ids</p>
        <ul>
          {!_.isEmpty(getCWEDetails()) &&
            !_.isEmpty(getCWEDetails().cveIdList) &&
            getCWEDetails().cveIdList.map((row, ind) => {
              return (
                <li key={`ind${ind}`}>
                  <a
                    target="_blank"
                    href={`https://galaxyguard.sec1.io/cve/${row}/?s=${row}`}
                  >
                    {row}
                  </a>
                </li>
              );
            })}
        </ul>
      </Drawer>
      <Drawer
        title={`What should I do?`}
        placement="right"
        size={"large"}
        onClose={() => setWhatNextModal(false)}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        open={whatNextModal}
        style={{ fontSize: "1rem" }}
      >
        <p className="">
          Please fix <b>22</b> critical and <b>305</b> high vulnerabilities.
        </p>
        <ul className="list-unstyled">
          <li className="mb-2">
            <b>Most vulnerable package</b>: pypi/tensorflow@2.3.0{" "}
          </li>
        </ul>
        <p className="mb-2">
          Do you want us to fix the vulnerabilities for you?
        </p>
        <p>
          <Button type={"primary"}>Subscribe to autofix edition</Button>
        </p>
      </Drawer>

      {openFixVulnerability && (
        <Modal
          title={<h3>Autofix Vulnerabilities</h3>}
          open={openFixVulnerability}
          onCancel={() => setOpenFixVulnerability(false)}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={null}
          width={1000}
        >
          <AutoFixVulnerabilityModal
            id={id}
            searchResults={searchResults}
            selectedFileRecord={selectedFileRecord}
            loadedResults={loadedResults}
            setOpenFixVulnerability={setOpenFixVulnerability}
            suggestedRemediation={suggestedRemediation}
            setSuggestedRemediation={setSuggestedRemediation}
          />
        </Modal>
      )}
    </section>
  );
}