import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
// import { Input, Space } from 'antd';

// import googleIcon from '../../../assets/img/google-icon-multicolor.svg';
// import facebook from '../../../assets/img/facebook-icon.svg';

import sec1Logo from '../../../assets/img/sec1-logo-svg-orange.svg';
import google from '../../../assets/img/google-icon-multicolor.svg';

import loginBack from '../../../assets/img/login-background.png';


// import { Button, Modal } from 'antd';

import { useDispatch } from "react-redux";

import { Button, Alert } from 'antd';
// import { Space } from 'antd';

import { useHistory } from "react-router-dom";

// import googleIcon from '../../../assets/img/google-icon-multicolor.svg';
import githubWhite from '../../../assets/img/github-white.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import OAuth2Login from 'react-simple-oauth2-login';
import _ from 'lodash';

import qs from 'qs';
import axios from 'axios';
import { updateToken, setIsFirstLogin, setLoggedInFrom } from '../../../shared/redux/reducers/authReducer';
import { loaderState } from '../../../shared/redux/reducers/scannerReducer';
// import PageLoader from "../../../shared/sharedComponents/pageLoader";
import Loader from "../../../shared/sharedComponents/loader";
import { GlobalConst } from '../../../shared/appConfig/globalConst';



import './styles.css';

export default function Login() {



    const dispatch = useDispatch();
    const history = useHistory();

    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    // const [loginFrom, setLoginFrom] = useState('');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [signingIn, setSigningIn] = useState(false);



    useEffect(() => {
        if (!_.isEmpty(code)) {
            getUserToken(code);
        }
    }, [code]);


    const getIsFirstLogin = async () => {
        return axios.post(`${GlobalConst.API_URL}/auth/foss/check-user`, { "idpType": "github" })
            .then(op => {
                // console.log("I am is first login here::", op);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    return op.data.isFirstLogin;
                }
                return false;
            })
            .catch((e) => {
                console.log("Exception: ", e);
                return false;
            })
    }

    const getUserToken = (code) => {
        setSigningIn(true);
        const payload = qs.stringify({
            "grant_type": "authorization_code", // refresh_token
            "code": code,
            "redirect_uri": GlobalConst.REDIRECT_URL,
            "scope": "openid offline_access",
            "client_id": GlobalConst.CLIENT_ID,
            "client_secret": GlobalConst.CLIENT_SECRET
        });
        const headers = { headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' } };
        dispatch(loaderState(true));
        axios.post(`${GlobalConst.AUTH_API_URL}/realms/cve-buster-prod/protocol/openid-connect/token`, payload, headers)
            .then(async (op) => {
                dispatch(loaderState(false));
                // setSigningIn(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    let tokenUpdated = false;
                    if (op.data.access_token) {
                        tokenUpdated = await localStorage.setItem("__t", op.data.access_token);
                    }
                    if (op.data.refresh_token) {
                        await localStorage.setItem("__r", op.data.refresh_token);
                    }
                    dispatch(updateToken(op.data.access_token));
                    // setLoginModalStatus(false);
                    const isFirstLogin = await getIsFirstLogin();
                    // console.log("Updating first time login", isFirstLogin)
                    dispatch(setIsFirstLogin({ isFirstLogin: isFirstLogin }));

                    if (isFirstLogin) {
                        // history.push("/welcome-screen");
                        window.location.href = GlobalConst.BASE_URL + '/welcome-screen';
                    }
                    else {
                        // history.push("/sca-dashboard");
                        window.location.href = GlobalConst.BASE_URL + '/dashboard';
                    }
                    // getUserLoginStatus();
                }
            })
            .catch(e => {
                dispatch(loaderState(false));
                setSigningIn(false);
                console.log("exception: ", e);
                if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data)) {
                    setMessage(e.response.data.error_description);
                }
            });
    }

    // const handleCancelCLick = () => {
    //     // setLoginModalStatus(false);
    // }

    const onSuccess = ({ code }, clickedButton) => {
        setCode(code);
        dispatch(setLoggedInFrom(clickedButton));
    }

    const onFailure = e => {
        console.log(e);
        setMessage("Sorry, something went wrong. Please try logging in again after some time.");

    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // console.log("Username::Password", username, password);

        if (!_.isEmpty(username) && !_.isEmpty(password)) {

            setSigningIn(true);
            const headers = { headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' } };
            axios
                .post(`${GlobalConst.AUTH_API_URL}/realms/cve-buster-prod/protocol/openid-connect/token`,
                    qs.stringify({
                        grant_type: 'password',
                        client_id: GlobalConst.CLIENT_ID,
                        client_secret: GlobalConst.CLIENT_SECRET,
                        username,
                        password,
                        scope: "openid"
                    }), headers)
                .then(async function (op) {
                    // setSigningIn(false);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        // console.log(response.data);
                        let tokenUpdated = false;
                        if (op.data.access_token) {
                            tokenUpdated = await localStorage.setItem("__t", op.data.access_token);
                        }
                        if (op.data.refresh_token) {
                            await localStorage.setItem("__r", op.data.refresh_token);
                        }
                        dispatch(updateToken(op.data.access_token));
                        // setLoginModalStatus(false);
                        const isFirstLogin = await getIsFirstLogin();
                        // console.log("Updating first time login")
                        dispatch(setIsFirstLogin({ isFirstLogin: isFirstLogin }));

                        if (isFirstLogin) {
                            // history.push("/welcome-screen");
                            window.location.href = GlobalConst.BASE_URL + '/welcome-screen';
                        }
                        else {
                            // history.push("/sca-dashboard");
                            window.location.href = GlobalConst.BASE_URL + '/dashboard';
                        }
                    }

                })
                .catch((e) => {
                    setSigningIn(false);
                    console.log("Exception: ", e);
                    if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data)) {
                        setMessage(e.response.data.error_description);
                    }
                });

        }


    }

    const handleCreateAccountClick = (e) => {
        e.preventDefault();

        // console.log("Handle create account click");
        history.push("/create-new-account");
    }

    const handleForgotPasswordClick = (e) => {
        e.preventDefault();
        // console.log("I am the handle Forgot password click");
        history.push("/forgot-password")
    }





    return (
        <section>
            <Helmet bodyAttributes={{ class: 'login-back' }}>
                <title>Login | Scopy - Sec1.io</title>
            </Helmet>



            <section className="mb-8 vh-100 d-flex align-items-center justify-content-center bg-customDark">
                <div className="container">
                    <div className="row mt-lg-n10 mt-md-n11 mt-n10">
                        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                            <div className="card z-index-0 custom-card login-card" style={{ backgroundImage: `url(${loginBack})` }}>

                                <div className="justify-content-center">
                                    <div className="text-center mx-auto">
                                        <div className="mt-5 login-logo-container">
                                            <img src={sec1Logo} alt="Sec1" />
                                        </div>

                                        <h1 className="text-white mt-4 welcome-text mb-0">Welcome back</h1>
                                        <p className="text-lead text-white welcome-subtext">Please enter you details to sign in</p>
                                    </div>
                                    {!_.isEmpty(message) && <div className="row px-4">
                                        <div className="card-body">
                                            <Alert showIcon message={<>{message}</>} type="error" className="d-flex align-items-start error-alert" />
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="row mt-3 px-4 mb-2">



                                    <div className="card-body">

                                        <div className="text-center mb-2">
                                            <OAuth2Login
                                                className='btn btn-shadow btn-custom'
                                                children={<><img src={githubWhite} className={'me-2'} style={{ width: 22 }} alt={'GitHub Login'} /> <span style={{ fontWeight: 600, fontSize: 14 }}>Login with GitHub</span></>}
                                                authorizationUrl={`${GlobalConst.AUTH_API_URL}/realms/cve-buster-prod/protocol/openid-connect/auth`}
                                                responseType="code"
                                                clientId={GlobalConst.CLIENT_ID}
                                                redirectUri={GlobalConst.REDIRECT_URL}
                                                onSuccess={(props) => onSuccess(props, 'github')}
                                                extraParams={{ kc_idp_hint: 'github', grant_type: 'authorization_code', flow: "auth-code" }}
                                                scope='openid'
                                                onFailure={onFailure} />
                                        </div>
                                        
                                        <div className="text-center">
                                            <OAuth2Login
                                                className='btn btn-shadow btn-custom'
                                                children={<><img src={google} className={'me-2'} style={{ width: 18 }} alt={'Google Login'} /> <span style={{ fontWeight: 600, fontSize: 14 }}>Login with Google</span></>}
                                                authorizationUrl={`${GlobalConst.AUTH_API_URL}/realms/cve-buster-prod/protocol/openid-connect/auth`}
                                                responseType="code"
                                                clientId={GlobalConst.CLIENT_ID}
                                                redirectUri={GlobalConst.REDIRECT_URL}
                                                onSuccess={(props) => onSuccess(props, 'google')}
                                                extraParams={{ kc_idp_hint: 'google', grant_type: 'authorization_code', flow: "auth-code" }}
                                                scope='openid'
                                                onFailure={onFailure} />
                                        </div>


                                        <div className="my-3 position-relative text-center">
                                            <p className="text-sm font-weight-bold or-text-container mb-0">
                                                <span className="or-text">or</span>
                                            </p>
                                        </div>


                                        <form onSubmit={handleFormSubmit}>
                                            <div className="mb-3">
                                                <label htmlFor="txtUsername" className="form-label" style={{ fontSize: 12 }}>Username</label>
                                                <input type="text" disabled={signingIn} value={username} onChange={(e) => { setUsername(e.target.value) }} className="form-control" id="txtUsername" placeholder="Enter username" />
                                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="txtPassword" className="form-label" style={{ fontSize: 12 }}>Password</label>
                                                <input type="password" disabled={signingIn} value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control" id="txtPassword" placeholder="Enter password" />
                                            </div>

                                            {/* <button type="submit" className="btn btn-primary-gradient w-100">
                                                <FontAwesomeIcon className="me-2" icon={faArrowRightToBracket} /> Sign in
                                            </button> */}
                                            <div className="d-flex align-items-center justify-content-end mb-3">
                                                <a href="#" style={{ fontSize: 13 }} onClick={handleForgotPasswordClick}>Forgot password?</a>
                                            </div>
                                            {/* <div style={{ borderTop: '1px solid #2b313e' }}> */}
                                            <Button type="default" htmlType={'submit'} loading={signingIn} className="btn btn-primary-gradient w-100">
                                                <FontAwesomeIcon className="me-2" icon={faArrowRightToBracket} />
                                                Sign in
                                            </Button>
                                            {/* </div> */}









                                        </form>
                                        <div className="text-center mt-4" style={{ fontSize: 13 }}>Don't have an account? <a href="#" onClick={handleCreateAccountClick}>Create an account</a></div>
                                    </div>






                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {signingIn && <Loader />}
        </section>
    );
}