import axios from "axios";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { Table, Tag, Space, Menu, Dropdown } from "antd";
import { getFontColor, getStrokeColor, extractDomain } from '../../shared/helper/genHelper';
import Loader from "../../shared/sharedComponents/loader";
import { ViewDetailsModal } from '../../shared/sharedComponents/dastSharedComponents/viewDetailsModalHelper';
import { GlobalConst } from "../../shared/appConfig/globalConst";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { LuHardDriveDownload } from "react-icons/lu";
import { exportToCSV } from "../../shared/helper/exportCSV";

const expandedRowRender = (props, setIsModalVisible, setSelectedData, getDetails) => {

    let subData = [];
    //console.log("Props", props);
    const columns = [
        {
            title: 'URL',
            key: 'url',
            dataIndex: 'url',
            width: '30%',
        },
        {
            title: 'Action',
            key: 'operation',
            width: '30%',
            render: (col, row) => {
                // console.log("row : ", row)
                return (
                    <Space size="middle">
                        <a href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                // setIsModalVisible(true);
                                // console.log("I am the row", row);
                                getDetails(row.details.dbMessageId);
                                setSelectedData(row);
                                // setIsModalVisible(true);
                                // console.log("I am the row", row);
                                // setSelectedData(row);
                            }}
                        >View Detail</a>
                    </Space>
                );
            },
        },
    ];

    if (!_.isEmpty(props) && !_.isEmpty(props.occurances)) {

        let newData = [];
        props.occurances.forEach((row, i) => {
            let tempJson = {
                key: i.toString(),
                url: row.url,
                details: row,
                cweId: `CWE-${props.key}`
            };
            tempJson.details["solution"] = props.solution;
            tempJson.details["reference"] = props.reference;
            newData.push(tempJson);
        })
        subData = newData;
    }
    else {
        subData = [];
    }
    return <Table columns={columns} dataSource={subData} pagination={false} locale={{ emptyText: <NoDataFound title={'No Data'} /> }} />;
};



export default function DastVulnerabilityTabView({ reportDetails, userPrefrences, reportId }) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    const [showLoader, setShowLoader] = useState(false);
    const [modalData, setModalData] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(reportDetails) && !_.isEmpty(reportDetails.reportId)) {
            getVulnerabilityViewData();
        }
    }, [reportDetails]);

    const getVulnerabilityViewData = () => {

        const postBody = {
            "reportId": reportDetails.reportId
        };
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/asset/report/vulnerability-view`, postBody)
            .then(op => {
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    updateTableData(op.data);
                }
                else {
                    setData([]);
                }
            })
            .catch(e => { setLoading(false); })
    }

    const columns = [
        {
            title: <><span className='me-3'>Vulnerability</span></>,
            dataIndex: 'vulnerability',
            key: 'vulnerability',
            // render: (e,f) => {console.log("e,f", e,f)}
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            align: 'center'
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            align: 'center',
            render: (val, row) => {
                switch (val.toLowerCase()) {
                    case 'critical':
                        return (<Tag
                            style={{
                                color: getFontColor("CRITICAL"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("CRITICAL")}
                            className={"mx-auto text-center"}
                        >CRITICAL</Tag>);
                    case 'high':
                        return (<Tag
                            style={{
                                color: getFontColor("HIGH"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("HIGH")}
                            className={"mx-auto text-center"}
                        >
                            HIGH
                        </Tag>);
                    case 'medium':
                        return (<Tag
                            style={{
                                color: getFontColor("MEDIUM"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("MEDIUM")}
                            className={"mx-auto text-center"}
                        >
                            MEDIUM
                        </Tag>);
                    case 'low':
                        return (<Tag
                            style={{
                                color: getFontColor("LOW"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("LOW")}
                            className={"mx-auto text-center"}
                        >
                            LOW
                        </Tag>);
                    default:
                        return (<Tag
                            style={{
                                color: getFontColor("NA"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("NA")}
                            className={"mx-auto text-center"}
                        >
                            NA
                        </Tag>);
                }
            }
        }
    ];

    const updateTableData = (vulRecords) => {
        let newData = [];
        if (!_.isEmpty(vulRecords.vulnerabilitySummary.vulnerabilityDetails)) {
            _.forEach(vulRecords.vulnerabilitySummary.vulnerabilityDetails, (el, key) => {
                newData.push({
                    key: key.toString(),
                    vulnerability: el.title,
                    count: el.count,
                    severity: el.severity,
                    occurances: el.occurrences,
                    solution: el.solution,
                    reference: el.reference
                })
            });
            //console.log("newData : ", newData);
            setData(newData);
        }
        else {
            setData([]);
        }
    }


    const getDetails = (dbMessageId) => {

        setShowLoader(true);
        const postBody = {
            "dbMessageId": dbMessageId,
            "reportId": reportId
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/message`, postBody)
            .then(op => {
                // console.log("Output: ", op);
                setShowLoader(false);
                setIsModalVisible(true);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setModalData(op.data);
                }

            })
            .catch(e => {
                setShowLoader(false);
                console.log("Exception: ", e);
            })
    }


    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedData(null);
    };


    const handleReportDownloadClick = async (type) => {
        try {
            setLoading(true);
            const response = await axios.get(`${GlobalConst.API_URL}/auth/dast/download-report?reportId=${reportId}&type=${type}`, {
                responseType: 'blob'
            });
            setLoading(false);
            if (!_.isEmpty(response) && response.data) {
                const blob = new Blob([response.data], {
                    type: response.headers['content-type'],
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `${reportId}-${type}.pdf`; // Set your desired filename here
                link.click();
                // Clean up by revoking the blob URL
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }
    }

    const handleDownloadMenuClick = ({ key }) => {
        handleReportDownloadClick(key)
    };

    if (loading) {
        return (<Loader />)
    }
    else {

        return (
            <section className="my-2">
                <span style={{ position: 'absolute', top: 0, right: 0 }}>
                    <Dropdown overlay={<Menu selectable
                        style={{
                            '.ant-menu-item-divider': {
                                backgroundColor: '#cecece'
                            }
                        }}
                        onClick={handleDownloadMenuClick}
                        items={[{
                            key: 'summary',
                            label: 'Summary Report',
                        },
                        {
                            type: 'divider',
                        },
                        {
                            key: 'detailed',
                            label: 'Detailed Report',
                        }
                        ]} className={'scroll-style bg-primary'} />} trigger={['click']}>
                        <button className="btn btn-sm custom-button d-flex align-items-center justify-content-center"
                        ><LuHardDriveDownload className="me-2" />Download Report</button>
                    </Dropdown>
                </span>
                <div>
                    <Table
                        className='custom-table'
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record, setIsModalVisible, setSelectedData, getDetails)
                        }}
                        dataSource={data}
                        size="small"
                        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                    />
                    {isModalVisible && <ViewDetailsModal
                        details={selectedData ? selectedData.details : undefined}
                        cweId={selectedData ? selectedData.cweId : undefined}
                        visible={isModalVisible}
                        onClose={handleCloseModal}
                        reportId={reportId}
                        modalData={modalData}
                    />}

                    {/* {isModalVisible && <ViewDetailsModal
                        details={selectedData ? selectedData.details : undefined}
                        cweId={selectedData ? selectedData.cweId : undefined}
                        visible={isModalVisible}
                        onClose={handleCloseModal}
                        reportId={reportId}
                        modalData={modalData}
                    />} */}
                </div>
                {showLoader && <Loader />}
            </section>
        )
    }

}