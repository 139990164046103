import { Button } from "antd"
import { useState } from "react";
import { PiPhoneCallLight } from "react-icons/pi";
import { BookDemo } from "./bookDemo";

export const NoSCASubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);

    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }

    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock SCA Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a SCA module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced SCA features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus}  productInfo={"SCA"}  setBookDemoModalStatus={setBookDemoModalStatus} />}
    </section>)
}

export const NoSASTSubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);

    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }

    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock SAST Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a SAST module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced SAST features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus} productInfo={"SAST"} setBookDemoModalStatus={setBookDemoModalStatus} />}

    </section>)
}

export const NoWebSecuritySubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);

    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }

    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock Web Security Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a Web Security module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced Web Security features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus}  productInfo={"Web Security"}  setBookDemoModalStatus={setBookDemoModalStatus} />}

    </section>)
}

export const NoCloudSubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);

    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }
    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock Cloud Security Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a Cloud Security module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced Cloud Security features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus} productInfo={"Cloud Security"}  setBookDemoModalStatus={setBookDemoModalStatus} />}

    </section>)
}

export const NoK8sSubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);
    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }

    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock Kubernetes Security Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a Kubernetes Security module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced Kubernetes Security features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus} productInfo={"Kubernetes Security"}  setBookDemoModalStatus={setBookDemoModalStatus} />}
    </section>)
}

export const NoContainerSubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);
    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }

    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock Container Security Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a Container Security module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced Container Security features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus} productInfo={"Container Security"}  setBookDemoModalStatus={setBookDemoModalStatus} />}
    </section>)
}

export const NoAPISubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);
    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }

    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock API Security Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a API Security module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced API Security features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus} productInfo={"API Security"}  setBookDemoModalStatus={setBookDemoModalStatus} />}

    </section>)
}

export const NoThreatVisionSubscription = () => {
    const [bookDemoModalStatus, setBookDemoModalStatus] = useState(false);
    const handleGetSubscription = (e) => {
        e.preventDefault();
        setBookDemoModalStatus(true);
    }
    return (<section style={{ marginTop: "8rem" }} className="mb-5">
        <div className="row justify-content-center">
            <div className="col-8">
                <div className="d-flex flex-column align-items-center">
                    <h2 className="mb-3">Unlock Threat Vision Features</h2>
                    <p className="mb-4 text-center">You’ve tried to access a Threat Vision module of Sec1 that is not included in your current subscription.<br/>
                    Contact our support team to explore subscription options and gain access to advanced Threat Vision features.</p>
                    <Button
                        onClick={handleGetSubscription}
                        type="primary"
                        size="large"
                    >
                        <PiPhoneCallLight/>
                        Contact Support
                    </Button>
                </div>

            </div>
        </div>
        {bookDemoModalStatus && <BookDemo bookDemoModalStatus={bookDemoModalStatus} productInfo={"Threat Vision"}  setBookDemoModalStatus={setBookDemoModalStatus} />}
    </section>)
}