import React, { useState, useEffect, useRef } from "react";
import { Radio, Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { TbGridScan } from "react-icons/tb";
import _ from 'lodash';
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import PieChart from "../../shared/sharedComponents/echartsGraphs/pieChart";
import { useSelector, useDispatch } from "react-redux";
import TrendGraph from "./trendGraph";
import ComponentTable from "./componentTable";
import axios from "axios";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { getTooltipName } from "../../shared/helper/getAssetImagesHelper";
import Loader from "../../shared/sharedComponents/loader";

import cloudWelcome from '../../assets/img/cloud-welcome.svg';

import IScanModal from "./iScanModal";
import { setCloudSecurityDashboardTableData, setCloudSecurityScanTriggeredState, setIScanModalState, updateCloudSecurityTriggeredScans } from "../../shared/redux/reducers/cloudSecurityReducer";
import { capitalizeFirstLetter, getPlanTypeFromProductKey, getProductSubscription } from "../../shared/helper/genHelper";

export default function CloudSecurityDashboard() {

    const dispatch = useDispatch();
    const cloudSecurityReducer = useSelector(state => state.cloudSecurityReducer);
    const subscribedProductsReducer = useSelector(state => state.productsReducer);

    const [issuesDistribution, setIssuesDistribution] = useState([]);
    const [trendValue, setTrendValue] = useState('7');
    const [cloudDashboardData, setCloudDashboardData] = useState({});
    const [loading, setLoading] = useState(false);
    const intervalId = useRef(null);

    useEffect(() => {
        if (cloudSecurityReducer && !_.isEmpty(cloudSecurityReducer.cloudSecurityTriggeredScans)) {
            // Accumulate report IDs
            // Clear previous interval if it exists
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
            // Set a new interval for polling
            intervalId.current = setInterval(() => {
                poll(cloudSecurityReducer.cloudSecurityTriggeredScans);
            }, GlobalConst.POLLING_LIMIT);

            // Cleanup interval on component unmount
            return () => {
                if (intervalId.current) {
                    clearInterval(intervalId.current);
                }
            };
        }
    }, [cloudSecurityReducer.cloudSecurityTriggeredScans]);

    const poll = (reportIds) => {
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/report/status`, { reportId: reportIds })
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    const updatedContainerTableData = cloudSecurityReducer.cloudSecurityDashTableData.map(record => {
                        const isPresent = op.data.find(opRecord => opRecord.reportId === record.reportId);
                        return isPresent ? { ...record, ...isPresent } : record;
                    });

                    dispatch(setCloudSecurityDashboardTableData(updatedContainerTableData));

                    const scanningOrInitiated = _.chain(updatedContainerTableData)
                        .filter(record => record.status.toLowerCase() === 'scanning' || record.status.toLowerCase() === 'initiated')
                        .map('reportId')
                        .value();
                    if (_.isEmpty(scanningOrInitiated)) {
                        clearInterval(intervalId.current);
                        dispatch(setCloudSecurityScanTriggeredState(false));
                        dispatch(updateCloudSecurityTriggeredScans([]));
                        getDashboardSummary();
                    }
                }
            })
            .catch(e => {
                if (!_.isEmpty(e) && !_.isEmpty(e.response) && !_.isEmpty(e.response.data) && !_.isEmpty(e.response.data.error)) {
                    clearInterval(intervalId.current);
                    dispatch(setCloudSecurityScanTriggeredState(false));
                    dispatch(updateCloudSecurityTriggeredScans([]));
                    window.location.reload();
                }
            });
    };

    useEffect(() => {
        getDashboardSummary();
    }, [])

    const getDashboardSummary = () => {
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/dashboard/summary`, {})
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setCloudDashboardData(op.data);
                    dispatch(setCloudSecurityDashboardTableData(op.data.cloudAssets));
                    if (!_.isEmpty(op.data.cloudAssets)) {
                        const scanningOrInitiated = _.chain(op.data.cloudAssets)
                            .filter(record => record.status.toLowerCase() === 'scanning' || record.status.toLowerCase() === 'initiated')
                            .map('reportId')
                            .value();
                        if (!_.isEmpty(scanningOrInitiated)) {
                            dispatch(setCloudSecurityScanTriggeredState(true));
                            dispatch(updateCloudSecurityTriggeredScans(scanningOrInitiated));
                        }
                    }
                } else {
                    // setHasError(true);
                    setCloudDashboardData({});
                    dispatch(setCloudSecurityDashboardTableData([]));
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                // setHasError(true);
                setCloudDashboardData({});
                dispatch(setCloudSecurityDashboardTableData([]));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        const processData = () => {
            const groupedData = _.chain(cloudDashboardData.cloudAssets)
                .groupBy('integrationType')
                .map((items, category) => ({
                    name: getTooltipName(category),
                    value: _.sumBy(items, item => item.critical + item.high + item.medium + item.low)
                }))
                .value();
            setIssuesDistribution(groupedData);
        }

        if (!_.isEmpty(cloudDashboardData) && _.has(cloudDashboardData, 'cloudAssets') && !_.isEmpty(cloudDashboardData.cloudAssets)) {
            processData();
        }

    }, [cloudDashboardData]);

    const handleTrendFilterChange = (e) => {
        setTrendValue(e.target.value);
    };

    const handleDashboardReload = (e) => {
        window.location.reload();
    }

    const handleiScanClick = (e) => {
            dispatch(setIScanModalState(true));
    }

    return (
        <>
            {
                (_.has(cloudDashboardData, 'cloudAssets') && _.isEmpty(cloudDashboardData.cloudAssets)) ?
                    <>{!loading && <section className="mx-3 my-2">
                        <section>
                            <div className="d-flex align-items-center">
                                <h2 style={{ lineHeight: 1 }} className="mb-2 text-white d-flex align-items-center justify-content-start">
                                    <FontAwesomeIcon icon={faCloud} className="me-2" />
                                    Cloud Security
                                </h2>
                            </div>
                        </section>
                        <section className="mb-5">
                            <div className="text-center mx-4 mt-4">
                                <img src={cloudWelcome} alt="welcome" className="mb-3" style={{ width: 200 }} />
                                <p className="text-white mb-4">
                                    {cloudSecurityReducer.cloudSecurityScanTriggered ? <>Your first scan is in progress. Please wait while we complete scanning...</>
                                        : <>You haven't run a Cloud Security scan yet. Start now to assess your Cloud Compliance and detect potential vulnerabilities.</>}
                                </p>
                                <Button className="sotcox-btn-warning" type="primary" onClick={handleiScanClick} loading={cloudSecurityReducer.cloudSecurityScanTriggered}>Scan</Button>
                            </div>
                        </section>
                    </section>}</>
                    :
                    <section className="mx-3 my-2">
                        <section>
                            <div className="d-flex align-items-start justify-content-between">
                                <div className="d-flex align-items-center">
                                    <h2 style={{ lineHeight: 1 }} className="mb-2 text-white d-flex align-items-center justify-content-start">
                                        <FontAwesomeIcon icon={faCloud} className="me-2" />
                                        Cloud Security
                                    </h2>
                                    <Tooltip title="Scan a repository">
                                        <button className="btn btn-warning py-1 ms-3 d-flex align-items-center justify-content-center" onClick={handleiScanClick}>
                                            <TbGridScan className={'me-2'} />iScan
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Refresh">
                                        <button className="btn btn-primary py-1 ms-2" onClick={handleDashboardReload}>
                                            <FontAwesomeIcon icon={faRotateRight} />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div>
                                    {!_.isEmpty(subscribedProductsReducer.subscribedProducts) && !_.isEmpty(subscribedProductsReducer.subscribedProducts.activePlans) &&
                                        !_.isEmpty(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.CLOUD_SECURITY))
                                        && <div><span className="product-plan-text">Plan - <span className={`${getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.SAST).sProductKey) === 'free' ? 'free-plan' : 'non-free-plan'}`}>{capitalizeFirstLetter(getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.CLOUD_SECURITY).sProductKey))}</span></span></div>
                                    }
                                </div>
                            </div>
                        </section>

                        <section className="mb-5">
                            <div className="row">
                                <div className="col-lg-5 custom-border-right">
                                    <div className="p-3 ps-0 d-flex align-items-center w-100 h-100">
                                        <div className="card-body">
                                            <div className="d-flex flex-row align-items-start justify-content-between">
                                                <div className="me-3">
                                                    <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Issues</p>
                                                    <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{(_.has(cloudDashboardData, 'total') && cloudDashboardData.total) ? cloudDashboardData.total : '-'}</h1>
                                                </div>
                                                <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Critical'} loadingRows={cloudSecurityReducer.cloudSecurityScanTriggered} dashboardData={(_.has(cloudDashboardData, 'critical') && cloudDashboardData.critical) ? cloudDashboardData.critical : '-'} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'High'} loadingRows={cloudSecurityReducer.cloudSecurityScanTriggered} dashboardData={(_.has(cloudDashboardData, 'high') && cloudDashboardData.high) ? cloudDashboardData.high : '-'} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Medium'} loadingRows={cloudSecurityReducer.cloudSecurityScanTriggered} dashboardData={(_.has(cloudDashboardData, 'medium') && cloudDashboardData.medium) ? cloudDashboardData.medium : '-'} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Low'} loadingRows={cloudSecurityReducer.cloudSecurityScanTriggered} dashboardData={(_.has(cloudDashboardData, 'low') && cloudDashboardData.low) ? cloudDashboardData.low : '-'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-6 custom-border-right">
                                            <div className="p-2">
                                                <div className="card-body">
                                                    <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                        <h5 className="fw-medium">Issues Distribution</h5>
                                                    </div>
                                                    <div style={{ height: 300 }}>
                                                        <PieChart data={issuesDistribution} legendHeading={'Issues Distribution'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="p-2">
                                                <div className="card-body">
                                                    <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                        <h5 className="fw-medium">Issues Trend</h5>
                                                        <div>
                                                            <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                                <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                                <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                                <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                    <div style={{ height: 300 }}>
                                                        <TrendGraph trendValue={trendValue} hasCloudData={(_.has(cloudDashboardData, 'cloudAssets') && !_.isEmpty(cloudDashboardData.cloudAssets)) ? true : false} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="mb-5">
                            <div className="card custom-card">
                                <div className="card-body">
                                    {
                                        _.has(cloudDashboardData, 'cloudAssets') && !_.isEmpty(cloudDashboardData.cloudAssets) ? <ComponentTable data={cloudDashboardData.cloudAssets} /> : <NoDataFound title={'No Data'} />
                                    }
                                </div>
                            </div>
                        </section>
                    </section>
            }
            {loading && <Loader />}
            {cloudSecurityReducer.iScanModalState && <IScanModal loading={loading} setLoading={setLoading} />}

        </>
    );
}