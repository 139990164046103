import { faCircleExclamation, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message, Modal } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { GlobalConst } from '../appConfig/globalConst';
import _ from "lodash";
import axios from 'axios';

export const BookDemo = ({ bookDemoModalStatus, productInfo, setBookDemoModalStatus }) => {
    const handleCancelCLick = () => {
        setBookDemoModalStatus(false);
    }

    const handleFormSubmit = (values, setSubmitting) => {
        const {
            fullName,
            txtEmail,
            company,
            phone,
            txtHelp
        } = values

        let data = {
            "type": `${productInfo}-Subscription`, "customerEmail": txtEmail,
            "metadata": {
                fullName,
                company
            }
        }
        if (phone) {
            data.metadata.phone = phone
        }
        if (!_.isEmpty(txtHelp)) {
            data.metadata.txtHelp = txtHelp
        }

        setSubmitting(true);
        axios
            .post(`${GlobalConst.API_URL}/notify/email/internal`, data)
            .then(op => {
                setSubmitting(false)
            })
            .catch(e => {
                setSubmitting(false)
                console.log("Exception: ", e);
                message.error({
                    content: (
                        <div className="d-flex align-items-center">
                            Error occurred while submitting form
                        </div>
                    ),
                    icon: <FontAwesomeIcon className="me-2 text-danger" icon={faCircleExclamation} />,
                    onClose: () => message.destroy(),
                });
            })
    }

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Full name is required'),
        company: Yup.string()
            .required('Company details is required'),
        txtEmail: Yup.string()
            .required('Email is required')
            .test('email-validation', 'Invalid email', function (value) {
                if (!value) return true; // Let the required validator handle empty case

                // Check email format
                if (!GlobalConst.EMAIL_REGEX.test(value)) {
                    return this.createError({
                        message: 'Enter a valid email address'
                    });
                }

                // Check domain restrictions
                const domain = value.split('@')[1].split('.')[0].toLowerCase();
                if (GlobalConst.RESTRICTED_EMAIL_DOMAINS.includes(domain)) {
                    return this.createError({
                        message: 'We only accept email addresses from business domains'
                    });
                }

                return true;
            })
    })


    return (
        <Modal
            title=""
            open={bookDemoModalStatus}
            footer={null}
            onCancel={handleCancelCLick}
            closeIcon={<FontAwesomeIcon className="fs-5" icon={faXmark} />}
            maskClosable={false}
            destroyOnClose={true}
        >
            <section className="mt-3 mb-4 mx-5">
                <div className="mb-3">
                    <div className="pt-4 mb-4">
                        <h5 className="mb-3 text-white" style={{ fontWeight: 600 }}>Welcome To Scopy</h5>
                        <p className={'text-white pb-2'}>Fill out the form and submit your query!</p>
                    </div>
                    <div className="d-grid gap-2 ">
                        <Formik
                            initialValues={{
                                fullName: "",
                                txtEmail: "",
                                company: "",
                                phone: "",
                                txtHelp: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                handleFormSubmit(values, setSubmitting);
                            }}>
                            {
                                ({ isSubmitting, setFieldValue, setTouched, values }) => {
                                    return (
                                        <Form>
                                            <div className="mb-3">
                                                <Field
                                                    id="fullName"
                                                    name="fullName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Full Name*"
                                                />
                                                <ErrorMessage name="fullName">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                            </div>
                                            <div className="mb-3">
                                                <Field
                                                    id="txtEmail"
                                                    name="txtEmail"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Business Email*"
                                                />
                                                <ErrorMessage name="txtEmail">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                            </div>
                                            <div className="mb-3">
                                                <Field
                                                    id="company"
                                                    name="company"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Company*"
                                                />
                                                <ErrorMessage name="company">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                            </div>
                                            <div className="mb-3">
                                                <Field
                                                    id="phone"
                                                    name="phone"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Field
                                                    as="textarea"
                                                    id="txtHelp"
                                                    name="txtHelp"
                                                    className="form-control"
                                                    placeholder="How can we help?"
                                                />
                                            </div>
                                            <section className="d-grid">
                                                <Button htmlType="submit" type="primary" size={'large'} loading={isSubmitting}
                                                    disabled={isSubmitting}>Submit Request</Button>
                                            </section>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                    <div className="mt-4 pt-2 mb-3 text-white">
                        <p className={' pb-2'}>By submitting this form, you agree to our&nbsp;<a href={'#'}>Terms of Use</a> &nbsp;and acknowledge our&nbsp; <a href={'#'}>Privacy Policy</a></p>
                    </div>
                </div>

            </section>
        </Modal>
    );
}